/* You can add global styles to this file, and also import other style files */
@import "../css/variables.scss";
@import "/../node_modules/@coreui/coreui/scss/coreui";
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
html, body, .main { height: 100%;
color: $primaryColor;
background-color: $primaryColor;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.main-container {
  padding-top: 55px;
  margin: 0 auto;
  width: 90%;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.main-title-container {
  padding-top: 10px;
  padding-bottom: 5px;
}
.main-title {
  color: $secondaryColor;
  font-weight: bold;
  font-size: 1.2rem;
  float:left;
}

.main-title-right {
  color: $secondaryColor;
  font-size: 1.3rem;
  float:right;
  font-weight: 500;
}

.horizontal-scroll-container{
  position: relative;
  z-index: 1;
  .horizontal-scroll-command{
    position:absolute;
    top: 50%;
    z-index: 2;
    background-color: transparent;
    border: none;
    &.prev{
      left: 0;
    }
    &.next{
      right: 0;
    }
    .icon{
      font-size: 3.5rem;
      color: $secondaryColor;
      cursor: pointer;
      height: 3.5rem;
      width: 3.5rem;
      color: $codeColor;
    }
  }
}

.owl-theme .owl-nav{
  visibility: hidden;
  height: 0;
}
